import React from "react";

const IconSecure = ({
  width = "53",
  height = "48",
  color = "var(--blue-500)",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 53 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22 40H31V46H22V40Z" stroke={color} stroke-width="3" />
      <path
        d="M13 46H40"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.7487 30.9975H21.3327C20.0491 30.9975 19 29.9475 19 28.6647V20.3015C19 19.0179 20.0491 17.9688 21.3327 17.9688H31.7487C33.0315 17.9688 34.0815 19.0179 34.0815 20.3015V28.6647C34.0815 29.9475 33.0315 30.9975 31.7487 30.9975Z"
        stroke={color}
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        d="M29.9634 17.9712V12.3327C29.9634 11.05 28.9134 10 27.6306 10H25.4519C24.1691 10 23.1191 11.05 23.1191 12.3327V17.9712"
        stroke={color}
        stroke-width="2.5"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.4313 23.0805C28.4313 22.041 27.5887 21.1992 26.5501 21.1992C25.5106 21.1992 24.668 22.041 24.668 23.0805C24.668 23.6825 24.9523 24.218 25.3925 24.5618V25.9146C25.3925 26.5507 25.9131 27.0713 26.5501 27.0713C27.1862 27.0713 27.7068 26.5507 27.7068 25.9146V24.5618C28.147 24.218 28.4313 23.6825 28.4313 23.0805Z"
        stroke={color}
        stroke-width="1.7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.3347 39.6273H6.66525C3.55 39.6273 2 38.078 2 34.9635V6.66373C2 3.54719 3.55 2 6.66525 2H46.3347C49.45 2 51 3.54719 51 6.66373V34.9635C51 38.078 49.45 39.6273 46.3347 39.6273Z"
        stroke={color}
        stroke-width="3"
      />
    </svg>
  );
};

export default IconSecure;
