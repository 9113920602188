import { useSpring, animated } from "@react-spring/web";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  TitleMed,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { useIsVisible } from "hooks/useIsVisible";
import { styled } from "linaria/react";
import React, { useEffect, useRef } from "react";

type feature = {
  icon: () => JSX.Element;
  title: string;
  blurb: string;
};

interface IFourColumnFeaturesProps {
  bgColor: string;
  title: string;
  features: feature[];
  style?: React.CSSProperties;
  layoutSize?: "compact" | "normal";
}

const FeatureSectionInner = styled(SectionInner)`
  flex-direction: column;
  gap: 5rem;
`;

const FeatureContainer = styled.div`
  display: flex;
  gap: 2.5rem;
  flex-wrap: wrap;
`;

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: calc((100% / 4) - (((4 - 1) / 4) * 2.5rem));
  @media (max-width: 1024px) {
    width: calc((100% / 2) - (((2 - 1) / 2) * 2.5rem));
  }
  @media (max-width: 820px) {
    width: 100%;
  }
`;

const IconContainer = styled.div``;

const FeatureTitle = styled(TitleSmall)<{ layoutSize: "compact" | "normal" }>`
  font-size: ${props => (props.layoutSize === "compact" ? "20px" : "25px")};
`;

const FourColumnFeatures = ({
  bgColor,
  title,
  features,
  style,
  layoutSize = "normal",
}: IFourColumnFeaturesProps) => {
  const sectionRef = useRef(null);
  const isVisible = useIsVisible(sectionRef);
  const [fadeIn, api] = useSpring(() => ({}), []);

  useEffect(() => {
    api.start({
      from: {
        opacity: 0,
        transform: "translateY(2.5rem)",
        width: "fit-content",
      },
      to: { opacity: 1, transform: "translateY(0rem)" },
      config: { mass: 3, bounce: 1, duration: 500 },
    });
  }, [isVisible]);

  return (
    <SectionContainer
      style={{ background: bgColor, ...style }}
      ref={sectionRef}
    >
      <animated.div style={fadeIn}>
        <FeatureSectionInner>
          <TitleMed style={{ fontSize: "32px", textAlign: "center" }}>
            {title}
          </TitleMed>
          <FeatureContainer>
            {features.map(item => (
              <Feature>
                <IconContainer>{item.icon()}</IconContainer>
                <TextContainer>
                  <FeatureTitle layoutSize={layoutSize}>
                    {item.title}
                  </FeatureTitle>
                  <MainParagraph>{item.blurb}</MainParagraph>
                </TextContainer>
              </Feature>
            ))}
          </FeatureContainer>
        </FeatureSectionInner>
      </animated.div>
    </SectionContainer>
  );
};

export default FourColumnFeatures;
